import moment from 'moment';

function validacaoDataHoje(text) {
    // Converter o texto para uma data no formato DD/MM/YYYY
    var mDate = moment(text, "DD/MM/YYYY");

    // Verificar se a data é válida
    if (!mDate.isValid()) {
        return 'Data inválida';
    }

    // Verificar se a data é menor que a data de hoje
    if (mDate.isBefore(moment().startOf('day'))) {
        return 'A data não pode ser anterior à data de hoje';
    }

    return true; // A data é válida e não é anterior a hoje
}

export default validacaoDataHoje;